import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import Image from 'next/image';
import { ProgramResponse } from '@solin-fitness/types';
import { buildImageUrl } from 'cloudinary-build-url';
import { MediaContextProvider, Media } from 'media';
import { hasStartedProgram } from 'queries/programs';
import Button from 'shared/Button';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';

const ProgramCarouselCard = ({
  count,
  page,
  program,
  hasAccess,
}: {
  count: number;
  page: string;
  program: ProgramResponse;
  hasAccess: boolean;
}) => {
  const ctaHref = hasAccess
    ? `/${page}/program/${program.id}`
    : program.isIncludedInMembership
    ? `/${page}/subscription/purchase`
    : `/${page}/program/${program.id}/purchase`;

  const isProgramStarted = hasStartedProgram(program.sessions);

  const [hover, setHover] = useState(false);
  const primaryVariants = {
    hover: { y: -20 },
    base: { y: 0 },
  };
  const secondaryVariants = {
    hover: { y: -20 },
    base: { y: 0 },
  };
  const animate = hover && !isMobile ? 'hover' : 'base';

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx(
        'overflow-hidden relative w-[300px]',
        count === 1 ? 'sm:w-[720px]' : 'sm:w-[319px]',
      )}
    >
      <ProgramCardImage
        count={count}
        verticalImage={
          program.verticalBackgroundImage || program.backgroundImagePublicId
        }
        horizontalImage={program.backgroundImagePublicId}
        isProgramStarted={isProgramStarted}
      />

      <CardBanner
        type={program.type}
        isStarted={isProgramStarted}
        isIncludedInMembership={program.isIncludedInMembership}
      />

      <div className="absolute inset-0 flex flex-col justify-end p-4">
        <motion.h6
          className="font-hk text-white text-center text-xl font-medium leading-md text-shadow-sm pb-6 whitespace-pre-wrap cursor-default"
          animate={animate}
          variants={primaryVariants}
        >
          {program.title}
        </motion.h6>
        <motion.div
          className={clsx(
            'flex flex-col items-center gap-2',
            hasAccess ? 'pb-2' : undefined,
          )}
          animate={animate}
          variants={secondaryVariants}
          transition={{ delay: hover ? 0.05 : 0.0 }}
        >
          <Button variant="fill" size="medium" link href={ctaHref}>
            {hasAccess ? (
              isProgramStarted ? (
                'Resume Program'
              ) : (
                'Start Program'
              )
            ) : program.isIncludedInMembership ? (
              'Subscribe for Access'
            ) : (
              <span className="font-hk">
                {'Buy '}
                <span className="font-hk capitalize">{program.type}</span>
                {` $${program.amount / 100}`}
              </span>
            )}
          </Button>
          {hasAccess ? null : (
            <Button
              variant="ghost-white"
              size="medium"
              link
              href={`/${page}/program/${program.id}`}
            >
              Learn More
            </Button>
          )}
        </motion.div>
      </div>
    </div>
  );
};

const ProgramCardImage = ({
  count,
  verticalImage,
  horizontalImage,
  isProgramStarted,
}: {
  count: number;
  verticalImage: string;
  horizontalImage: string;
  isProgramStarted: boolean;
}) => {
  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="md">
        {/* if count is 1, show the horizontal image */}
        {count === 1 ? (
          <AspectRatio.Root
            ratio={16 / 9}
            className={clsx(
              'relative  w-[720px] rounded-xl overflow-hidden leading-img bg-black-700',
              isProgramStarted
                ? 'border-[3px] border-solid border-red-300'
                : '',
            )}
          >
            <Image
              src={buildImageUrl(horizontalImage, {})}
              layout="fill"
              objectFit="cover"
              className="w-full h-full"
            />
            <CardGradient />
          </AspectRatio.Root>
        ) : (
          <AspectRatio.Root
            ratio={9 / 16}
            className={clsx(
              'relative rounded-xl overflow-hidden leading-img bg-black-700',
              isProgramStarted
                ? 'border-[3px] border-solid border-red-300'
                : '',
            )}
          >
            <Image
              src={buildImageUrl(verticalImage, {})}
              layout="fill"
              objectFit="cover"
              className="w-full h-full"
            />
            <CardGradient />
          </AspectRatio.Root>
        )}
      </Media>
      <Media lessThan="md">
        <AspectRatio.Root
          ratio={9 / 16}
          className={clsx(
            'relative rounded-xl overflow-hidden leading-img bg-black-700',
            isProgramStarted ? 'border-[3px] border-solid border-red-300' : '',
          )}
        >
          <Image
            src={buildImageUrl(verticalImage, {})}
            layout="fill"
            objectFit="cover"
            className="w-full h-full"
          />
          <CardGradient />
        </AspectRatio.Root>
      </Media>
    </MediaContextProvider>
  );
};

const CardGradient = () => {
  return (
    <div className="absolute h-2/5 left-0 right-0 bottom-0 bg-gradient-to-t from-black-700 to-transparent" />
  );
};

const CardBanner = ({
  type,
  isStarted,
  isIncludedInMembership,
}: {
  type: string;
  isStarted: boolean;
  isIncludedInMembership: boolean;
}) => {
  return isStarted ? (
    <div className="absolute top-4 -left-12 px-3 py-[9px] bg-red-500 -rotate-45 w-44">
      <p className="font-hk text-sm leading-sm tracking-sm text-white whitespace-pre text-center">
        <span className="capitalize">{type}</span>
        {`\nStarted`}
      </p>
    </div>
  ) : isIncludedInMembership ? (
    <div className="absolute top-4 -left-12 px-3 py-[9px] bg-black-700/80 -rotate-45 w-44">
      <p className="font-hk text-sm leading-sm tracking-sm text-white whitespace-pre text-center">
        {`Included\nin subscription`}
      </p>
    </div>
  ) : null;
};

export { ProgramCarouselCard };
