import {
  CalendarResponse,
  PageConfigResponse,
  PageSubscriptions,
  UserMetaDataResponse,
  UserType,
} from '@solin-fitness/types';
import React from 'react';
import CreatorLandingPageBanner from './Components/CreatorLandingPageBanner';
import styled from 'styled-components';
import YourAccessSection from './Components/YourAccessSection';
import CreatorUpcomingSessions from './Components/CreatorUpcomingSessions';
import CreatorLearnMore from './Components/CreatorLearnMore';
import CreatorTestimonialsSection from './Components/CreatorTestimonialsSection';
import Spacer, { Size } from 'shared/Spacer';
import { sort } from 'ramda';
import EmailListForm from './Components/EmailListForm';
import { usePublicCalendar } from 'queries/calendar';
import { usePrograms } from 'queries/programs';
import { Spinner } from 'shared/Spinner';
import { LoadingWrap } from './Components/CreatorLandingPage.styles';
import { getSectionOrder } from './Components/creatorLandingPageHelpers';
import { DateTime } from 'luxon';
import { SubscriptionStatus } from '@solin-fitness/types';
import { useRouter } from 'next/router';
import { reactivateSubscription } from 'queries/user';
import { useMutation, useQueryClient } from 'react-query';
import { ReactivateSubscriptionModal } from './Components/ReactivateSubscriptionModal';
import { Media, MediaContextProvider } from 'media';
import { AccessIncludeSection } from 'new-components/creator-landing-page/your-access-section';
import { useSessions } from 'queries/sessions';
import { UpcomingSessionSection } from 'new-components/creator-landing-page/upcoming-session-section';
import { LearnMoreSection } from 'new-components/creator-landing-page/learn-more-section';
import { TestimonialSection } from 'new-components/creator-landing-page/testimonial-section';
import { Footer } from 'components/LandingPage/Footer';
import clsx from 'clsx';
import { ProgramsDisplayCarousel } from 'new-components/multiple-programs/display-carousel/programs-display-carousel';

const removePastCalendarItemsAndSort = (
  items: CalendarResponse[],
): CalendarResponse[] => {
  const currentDate = new Date();
  const upcomingCalendarItems = items.filter((item) => {
    const date = DateTime.fromJSDate(new Date(item.date))
      .plus({ minutes: item.data.length })
      .toJSDate();

    return date > currentDate;
  });

  const sortedCalendarItems = sort(
    (a: CalendarResponse, b: CalendarResponse) => {
      const aDate = new Date(a.date).getTime();
      const bDate = new Date(b.date).getTime();
      return aDate - bDate;
    },
    upcomingCalendarItems,
  );
  return sortedCalendarItems;
};

export const findLowestSubscription = (subscriptions: PageSubscriptions[]) => {
  const activeSubscriptions = subscriptions.filter((sub) => sub.isActive);
  if (activeSubscriptions.length === 0) {
    return null;
  }
  const sortedSubscriptions = sort(
    (a: PageSubscriptions, b: PageSubscriptions) => {
      const aPrice = a.amount;
      const bPrice = b.amount;
      return aPrice - bPrice;
    },
    activeSubscriptions,
  );

  return sortedSubscriptions[0];
};

const sectionColor = (val: boolean) => (val ? '' : 'bg-off-white-500');

interface Props {
  config: PageConfigResponse;
  metadata: UserMetaDataResponse | undefined;
}

const CreatorLandingPage = ({ config, metadata }: Props) => {
  const router = useRouter();

  const {
    url,
    id,
    config: {
      pageDescription,
      trialPeriodDays,
      lightLogoPublicId,
      backgroundImage1PublicId,
      backgroundImage2PublicId,
      teaserThumbnailPublicId,
      teaserVideo,
      landingPageListItems,
      learnMoreImagePublicId,
      verticalBackgroundImage,
      bio,
      subscriptions,
      testimonials,
    },
  } = config;

  const subscriptionsToPage = metadata?.subscriptions.filter(
    (sub) =>
      sub.pageId === id &&
      (sub.status === SubscriptionStatus.canceled ||
        sub.status === SubscriptionStatus.incompleteExpired),
  );

  const hasCanceledSubscription = !!subscriptionsToPage?.length;

  const lowestSubscription = findLowestSubscription(subscriptions);

  const { data: upcomingSessions } = usePublicCalendar(
    url,
    1,
    2,
    removePastCalendarItemsAndSort,
  );

  const { data: programsRaw } = usePrograms(url, undefined);

  const { data: sessions } = useSessions(url);

  const programs = programsRaw
    ?.filter((program) => program.published)
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

  const adminCreator = config.users.find(
    (user) => user.type === UserType.adminCreator,
  );

  const creatorName = adminCreator?.profile.firstName;
  const creatorFullName = `${adminCreator?.profile?.firstName} ${adminCreator?.profile?.lastName}`;

  const queryClient = useQueryClient();
  const reactivate = useMutation((subscriptionId: string) =>
    reactivateSubscription(subscriptionId),
  );
  const handleReactivate = () => {
    if (subscriptionsToPage?.length) {
      reactivate.mutate(subscriptionsToPage[0].subscriptionId, {
        onSuccess: () => {
          queryClient.invalidateQueries('metadata');
          router.push(url);
        },
      });
    }
  };
  const isOpenReactivateModal = !!router.query.reactivate;
  const closeReactivateModal = () => {
    router.push(url, undefined, { shallow: true });
  };

  return (
    <>
      <CreatorLandingPageBanner
        page={url}
        pageId={id}
        logo={lightLogoPublicId}
        verticalBackgroundImage={verticalBackgroundImage}
        backgroundImage={backgroundImage1PublicId}
        description={pageDescription}
        hasSubscription={!!lowestSubscription}
        purchasedLiveWorkouts={metadata?.purchasedClasses || []}
        purchasedEvents={metadata?.purchasedEvents || []}
        trialPeriod={trialPeriodDays}
        creator={creatorFullName}
        hasCanceledSubscription={!!hasCanceledSubscription}
      />

      <DesktopBannerSpacer />

      {programs && upcomingSessions && sessions ? (
        (() => {
          const hasSubscription = !!lowestSubscription;
          const hasUpcomingSessions = !!upcomingSessions.length;
          const hasPrograms = !!programs.length;
          const hasTestimonials = !!testimonials?.length;

          const sectionOrder = getSectionOrder(
            hasSubscription,
            hasUpcomingSessions,
            hasPrograms,
            hasTestimonials,
          );

          return (
            <MediaContextProvider>
              <Media greaterThanOrEqual="md">
                <YourAccessSection
                  page={url}
                  image={teaserThumbnailPublicId}
                  video={teaserVideo}
                  details={landingPageListItems}
                  hasSubscription={!!lowestSubscription}
                  trialPeriod={trialPeriodDays}
                  hasCanceledSubscription={!!hasCanceledSubscription}
                />

                {hasSubscription ? <SectionSpacer /> : null}

                <CreatorUpcomingSessions
                  page={url}
                  image={backgroundImage2PublicId}
                  imageRight={sectionOrder[1]}
                  upcomingSessions={upcomingSessions || []}
                  hasSubscription={!!lowestSubscription}
                  purchasedLiveWorkouts={metadata?.purchasedClasses || []}
                  purchasedEvents={metadata?.purchasedEvents || []}
                  trialPeriod={trialPeriodDays}
                  hasCanceledSubscription={!!hasCanceledSubscription}
                />

                {hasUpcomingSessions ? <SectionSpacer /> : null}

                {programs && programs.length > 0 && (
                  <div
                    className={clsx(
                      'flex justify-center items-center py-6',
                      sectionColor(sectionOrder[1]),
                    )}
                  >
                    <ProgramsDisplayCarousel
                      page={url}
                      programs={programs}
                      purchasedPrograms={metadata?.purchasedPrograms || []}
                      isSubscribed={false}
                    />
                  </div>
                )}

                {hasPrograms ? <SectionSpacer /> : null}

                <CreatorLearnMore
                  page={url}
                  image={learnMoreImagePublicId}
                  imageRight={sectionOrder[3]}
                  bio={bio}
                  hasSubscription={!!lowestSubscription}
                  trialPeriod={trialPeriodDays}
                  hasCanceledSubscription={!!hasCanceledSubscription}
                />

                {hasTestimonials ? <SectionSpacer /> : null}

                <CreatorTestimonialsSection
                  page={url}
                  noBackground={sectionOrder[4]}
                  testimonials={testimonials}
                  hasSubscription={!!lowestSubscription}
                  trialPeriod={trialPeriodDays}
                  hasCanceledSubscription={!!hasCanceledSubscription}
                />

                {!hasSubscription ? <SectionSpacer /> : null}

                {!!!hasSubscription ? (
                  <EmailListSection
                    style={{
                      '--background-color': sectionOrder[5]
                        ? undefined
                        : 'var(--color-off-white-500)',
                    }}
                  >
                    <EmailListWrap>
                      <EmailListForm
                        size="large"
                        creator={creatorName}
                        page={url}
                      />
                    </EmailListWrap>
                  </EmailListSection>
                ) : null}
              </Media>

              {/* mobile */}
              <Media lessThan="md">
                <AccessIncludeSection
                  details={landingPageListItems}
                  video={teaserVideo}
                />

                {programs.length ? (
                  <section
                    className={clsx(
                      'flex justify-center items-center py-6',
                      'even:bg-off-white-500',
                    )}
                  >
                    <ProgramsDisplayCarousel
                      page={url}
                      programs={programs}
                      purchasedPrograms={metadata?.purchasedPrograms || []}
                      isSubscribed={false}
                    />
                  </section>
                ) : null}

                <UpcomingSessionSection
                  sessions={sessions}
                  page={url}
                  className="even:bg-off-white-500"
                />
                <TestimonialSection
                  testimonials={testimonials}
                  className="even:bg-off-white-500"
                />
                <LearnMoreSection
                  bio={bio}
                  page={url}
                  hasSubscription={!!lowestSubscription}
                />
              </Media>

              <FooterWrap>
                <Media greaterThanOrEqual="md">
                  <Spacer
                    size={Size.spacing96}
                    axis="vertical"
                    color={
                      sectionOrder[6] ? undefined : 'var(--color-off-white-500)'
                    }
                  />
                </Media>
                <Footer theme="light" />
              </FooterWrap>

              {hasCanceledSubscription ? (
                <ReactivateSubscriptionModal
                  isOpen={isOpenReactivateModal}
                  onDismiss={closeReactivateModal}
                  onReactivate={handleReactivate}
                  isLoading={reactivate.isLoading}
                />
              ) : null}
            </MediaContextProvider>
          );
        })()
      ) : (
        <LoadingWrap>
          <Spinner
            size="large"
            color="var(--color-primary-500)"
            ringColor="var(--color-gray-500)"
          />
        </LoadingWrap>
      )}
    </>
  );
};

const DesktopBannerSpacer = styled.div`
  padding-bottom: var(--spacing-80);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-bottom: 0;
  }
`;

const SectionSpacer = styled.div`
  padding-bottom: var(--spacing-160);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-bottom: 0;
  }
`;

const FooterWrap = styled.div`
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
`;

const EmailListSection = styled.section`
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--background-color);
  padding-top: var(--spacing-48);
`;

const EmailListWrap = styled.div`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

export default CreatorLandingPage;
